<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    >
      <!-- Mask -->
      <span class="mask"></span>
      <!-- Header container -->
<!--      <div class="container-fluid d-none d-lg-flex align-items-center my&#45;&#45;4">-->
<!--        <div class="row">-->
<!--          <div class="col-12">-->
<!--            <h1 class="text-white">Добро пожаловать!</h1>-->
<!--            <p class="text-white mt-0 mb-5">-->
<!--              Здесь вы можете отредактировать свой профиль-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </base-header>

    <div class="container-fluid mt--7">

      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <user-card />
          <user-documents />
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <user-info />
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import UserCard from "./UserCard";
import UserInfo from "../../shared/User/UserInfo";
import UserDocuments from "./UserDocuments.vue";
export default {
  name: "UserProfile",
  components: {
    UserDocuments,
    UserInfo,
    UserCard
  },
};
</script>
<style></style>
